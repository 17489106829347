<template>
  <div class="trend">
    <div class="tr-header">
      <span>平台人数趋势图</span>
      <span>监控日期&nbsp;
        <el-date-picker
          v-model="value"
          type="date"
          value-format="timestamp"
          placeholder="选择日期"
          :clearable="false"
          @change="getPlatformOnlineDataEvent">
        </el-date-picker>
      </span>
    </div>
    <div class="tr-charts">
      <v-chart class="chart-school" :option="option" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'trend',
  data() {
    return {
      value: '',
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          bottom: '10%',
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '4%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: [],
            axisLabel: {
              interval: 5
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '当前在线人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '当前课件学习人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '当前考试人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '当前直播观看人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    this.value = this.$way.dateReturnTimestamp(new Date())
    this.getPlatformOnlineDataEvent()
  },
  methods: {
    getPlatformOnlineDataEvent() {
      this.$request.getPlatformOnlineData({time: this.value}).then(res => {
        if (res.data.code == 0) {
          this.option.xAxis[0].data = []
          this.option.series[0].data = []
          this.option.series[1].data = []
          this.option.series[2].data = []
          this.option.series[3].data = []
          res.data.data.forEach(element => {
            // if(element.time/1000/60%30 == 0) {
              this.option.xAxis[0].data.push(this.$way.timestampReturnDate(element.time, 'timeHM'))
              this.option.series[0].data.push(element.onlineCount)
              this.option.series[1].data.push(element.studyCount)
              this.option.series[2].data.push(element.examCount)
              this.option.series[3].data.push(element.liveCount)
            // }
          });
          this.option.dataZoom = [
            {
              show: this.option.xAxis[0].data.length > 5 ? true : false,
              // show: true,
              height: 12,
              xAxisIndex: [0],
              bottom: "4%",
              // start: 0,
              // end: 100,
              // maxValueSpan: 15,
              handleIcon:
                "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
              handleSize: "110%",
              handleStyle: {
                color: "#d3dee5",
              },
              // textStyle: {
              //   color: "#666",
              // },
              textStyle: false,
              borderColor: "#90979c",
            }
          ]
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
  .trend {
    background-color: #fff;
    box-sizing: border-box;
    margin: 20px 0;
    .tr-header {
      padding: 30px;
      border-bottom: 1px solid #EFEFEF;
      display: flex;
      align-items: center;
      & > span:nth-of-type(1) {
        font-size: 26px;
        color: #16222E;
        margin-right: 24px;
      }
      & > span:nth-of-type(2) {
        font-size: 14px;
        color: #363840;
      }
      /deep/ .el-input__inner {
        height: 34px;
      }
      /deep/ .el-input__icon {
        height: 34px;
      }
    }
    .tr-charts {
      padding: 30px;
      .chart-school {
        height: 360px;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
      }
    }
  }
</style>